import React from 'react';
import { Box, Text, Button, Flex } from '@chakra-ui/react';

function Tags({ FilterTag }) {
  return (
    <Flex
      mt="5vh"
      minH="100px"
      alignItems="center"
      justifyContent="space-evenly"
      flexWrap="wrap"
    >
      <Button
        w={['100%', '100%', '120px', '120px']}
        m="2"
        value="Damian Wayne"
        onClick={FilterTag}
      >
        Damian Wayne
      </Button>
      <Button
        w={['100%', '100%', '120px', '120px']}
        m="2"
        value="Jon Kent"
        onClick={FilterTag}
      >
        Jon Kent
      </Button>
      <Button
        w={['100%', '100%', '120px', '120px']}
        m="2"
        value="Art Curry"
        onClick={FilterTag}
      >
        Art Curry
      </Button>
      <Button
        w={['100%', '100%', '120px', '120px']}
        m="2"
        value="Tai Pham"
        onClick={FilterTag}
      >
        Tai Pham
      </Button>
      <Button
        w={['100%', '100%', '120px', '120px']}
        m="2"
        value="Connor Queen"
        onClick={FilterTag}
      >
        Connor Queen
      </Button>
      <Button
        w={['100%', '100%', '120px', '120px']}
        m="2"
        value="Bart Allen"
        onClick={FilterTag}
      >
        Bart Allen
      </Button>
      <Button
        w={['100%', '100%', '120px', '120px']}
        m="2"
        value="Beast Boy"
        onClick={FilterTag}
      >
        Beast Boy
      </Button>
      <Button
        w={['100%', '100%', '120px', '120px']}
        m="2"
        value="Billy Batson"
        onClick={FilterTag}
      >
        Billy Batson
      </Button>
      <Button
        w={['100%', '100%', '120px', '120px']}
        m="2"
        value="Cubbie"
        onClick={FilterTag}
      >
        Cubbie
      </Button>
      <Button
        w={['100%', '100%', '120px', '120px']}
        m="2"
        value="Saeby"
        onClick={FilterTag}
      >
        Saeby
      </Button>
      <Button
        w={['100%', '100%', '120px', '120px']}
        m="2"
        value="Otto"
        onClick={FilterTag}
      >
        Otto
      </Button>
      <Button
        w={['100%', '100%', '120px', '120px']}
        m="2"
        value=""
        onClick={FilterTag}
      >
        All
      </Button>
    </Flex>
  );
}

export default Tags;
