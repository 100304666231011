import { useState, useEffect } from 'react';
import { projectFirestore } from '../firebase/firebase';


const useFirestore = (collection) => {
    const [docs, setDocs] = useState();

    useEffect(() => {
        const unsub = projectFirestore.collection(collection)
        .orderBy('order', 'desc') // 'desc' will have the newest at the top.
        .onSnapshot((snap) =>{
            let documents = [];
            snap.forEach(doc => {
                documents.push({...doc.data(), id: doc.id})
            });
            setDocs(documents);
        });

        return () => unsub();
    }, [collection])

    return { docs };
}

export default useFirestore