import React, { useState, useEffect } from "react";
import useFireStore from "../firebase/useFireStore";
import { Link, useLocation, useParams } from "react-router-dom";
import NewReader from "../NewReader";
import {Box, Center, Flex, Image, Text, Skeleton,Heading, SkeletonText, SkeletonCircle,Spinner, VStack, HStack, Stack} from '@chakra-ui/react'

function LinkedComicChrono() {
    const {id} = useParams();
  const { docs } = useFireStore("comics-chrono");
  let location = useLocation();
  let background = location.state && location.state.background;
  const [modalComic, setModalComic] = useState({url: 'https://via.placeholder.com/150"'})
  const [comics, setComics] = useState(null);

  useEffect(() => {

    if (docs == undefined) {

      let skeletonArray = [1]

      let skeleton = skeletonArray.map((doc, index) => (
        <VStack
        key={index} 
        boxShadow="full" 
        borderRadius="25px" 
        bg="white" 
        p="5"
        w={["100%","100%","800px","800px"]}
        h="800px"
         key={doc.id}>
          <Flex    justifyContent="space-between" fontFamily="Sniglet, cursive;" w="100%" >
            <VStack justifyContent="flex-start" d="flex" justify="flex-start" w="100%" px="5" py="1">
              <SkeletonText/>
              <SkeletonText/>
              <Flex flexWrap="wrap"  w="100%">
              <SkeletonText/>
              </Flex>
              </VStack>
          </Flex>
          <Center h="100%"><Spinner/></Center>
        </VStack>
      ))

      setComics(skeleton)
    }

    if (docs) {
        let doc = docs.find(doc => doc.id == id)
    if (doc) {
        setComics(<VStack 
            boxShadow="full" 
            borderRadius="25px" 
            bg="white" 
            p="5"
             key={doc.id}>
              <Flex h="100%"   justifyContent="space-between" fontFamily="Sniglet, cursive;" w="100%" >
                <VStack justifyContent="flex-start" d="flex" justify="flex-start" w="100%" px="5" py="1">
                  <Heading w="100%" color="#91C1E2">{doc.title}</Heading>
                  <Text w="100%" color="#91C1E2">{doc.desc}</Text>
                  <Flex flexWrap="wrap"  w="100%">
                    {doc.tags.map((tag, id) => (
                        <Text mx="1" my="1" px="3" key={id} borderRadius="3xl" color="white" bg="#92C3E0">{tag}</Text>
                    ))}
                  </Flex>
                  </VStack>
        
              </Flex>
              {/* <Link key={doc.id} to={{pathname:`/comic/${FindFileName(doc.fileName)}`, state:{background:location}}}> */}
              {/* </Link> */}
                <Image
                w={["100%","100%","800px","800px"]}
                h="100%"
                objectFit="cover"
                  src={doc.url}
                  fallback={comics}
                  alt="uploaded"
                />
            </VStack>)

    }

}





    // if (docs == undefined) {

    //   let skeletonArray = [1,2,3,4,5,6]

    //   let skeleton = skeletonArray.map((doc, index) => (
    //     <VStack 
    //     boxShadow="2xl"  
    //     borderRadius="25px" 
    //     bg="white" 
    //     p="5"
    //     w={["100%","100%","800px","800px"]} key={index}>
    //       <Flex h="100%"   justifyContent="space-between" fontFamily="Sniglet, cursive;" w="100%" >
    //         <VStack justifyContent="flex-start" d="flex" justify="flex-start" w="100%" px="5" py="1">
    //           <Heading w="100%" color="#91C1E2"><SkeletonText/></Heading>
    //           <Text w="100%" color="#91C1E2"><SkeletonText/></Text>
    //           <HStack w="100%">
    //               <Flex px="3" borderRadius="3xl"  bg="#92C3E0">
    //                 <Text w="50px" color="white"><SkeletonText/></Text>
    //               </Flex>
    //           </HStack>
    //           </VStack>

    //       </Flex>
    //         <Spinner />
    //     </VStack>
    //   ))

    //   setComics(skeleton)
    // }

    // function FindFileName(name) {
    //   let arr = name.split('.')
    //   console.log(arr[0])

    //   return arr[0]
    // }


  }, [docs])

  return (
    <Center bg="linear-gradient(
      0deg,
      rgba(204, 230, 245, 1) 0%,
      rgba(180, 215, 237, 1) 37%,
      rgba(145, 194, 224, 1) 100%
    );">
      <Link to="/newreader">
        <NewReader />
      </Link>
      <VStack w="100%" mb="20"  mt="20" spacing="20">
        {comics}
      </VStack>
    </Center>
  );
}

export default LinkedComicChrono;
