import React, { useState, useEffect } from "react";
import Tags from "./containers/Tags";
import useFireStore from "./firebase/useFireStore";
import ComicsModal from "./containers/ComicsModal";
import {useLocation} from 'react-router-dom'
import {Box, Center, Text, SimpleGrid, GridItem, HStack,Spinner} from '@chakra-ui/react'


function Gallery() {
  const { docs } = useFireStore("comics");
  const [searchTerm, setSearchTerm] = useState("");
  const [comics, setComics] = useState([])
  const [loaded, setLoaded]= useState(false);
  let location = useLocation();
  let background = location.state && location.state.background;

  useEffect(() => {
    if (docs !== undefined) {
      setComics(docs)
      setLoaded(true);
    }
   
  }, [docs])

  

  const FilterTag = (e) => {
    e.preventDefault();
    setSearchTerm(e.currentTarget.value);
  };
  const filteredDocs = !searchTerm
    ? comics
    : comics && comics.filter((doc) => doc.tags.includes(searchTerm));

  console.log(comics && comics.map((doc) => doc.tags));

  return (
    <Center pos="relative" w="100%" h="100%" bg="linear-gradient(
      0deg,
      rgba(204, 230, 245, 1) 0%,
      rgba(180, 215, 237, 1) 37%,
      rgba(145, 194, 224, 1) 100%
    );">
    <Box pos="relative" h="100%" py="5" w="60%" >
    <Tags FilterTag={FilterTag} />
    {loaded ? <SimpleGrid pt="10" columns={[1,1,2,3]} spacingX="40px" spacingY="20px">
      {comics &&
        filteredDocs.map((doc,index) => (
          <Box boxShadow="md" bg="white" p="2" borderRadius="15px" key={index}>
  <ComicsModal height="300px" objectFit={'cover'} background={background} doc={doc}/>
  </Box>
        ))}
</SimpleGrid> : <SimpleGrid  pt="10" columns={[1,1,2,3]} spacingX="40px" spacingY="20px">
  <Center borderStyle="solid" boxShadow="md" borderWidth="1px" bg="white" p="2" borderRadius="15px" height="300px"><Spinner/></Center>
  <Center borderStyle="solid" boxShadow="md" borderWidth="1px" bg="white" p="2" borderRadius="15px" height="300px"><Spinner/></Center>
  <Center borderStyle="solid" boxShadow="md" borderWidth="1px" bg="white" p="2" borderRadius="15px" height="300px"><Spinner/></Center>
  <Center borderStyle="solid" boxShadow="md" borderWidth="1px" bg="white" p="2" borderRadius="15px" height="300px"><Spinner/></Center>
  <Center borderStyle="solid" boxShadow="md" borderWidth="1px" bg="white" p="2" borderRadius="15px" height="300px"><Spinner/></Center>
  <Center borderStyle="solid" boxShadow="md" borderWidth="1px" bg="white" p="2" borderRadius="15px" height="300px"><Spinner/></Center>
</SimpleGrid>}
  </Box>
  </Center>
  );
}

export default Gallery;
