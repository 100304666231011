import damian1 from './damian1.jpeg';
import damian2 from './damian2.jpeg';
import damian3 from './damian3.jpeg';
import jon1 from './jon1.jpeg';
import jon2 from './jon2.jpeg';
import jon3 from './jon3.jpeg';
import connor1 from './connor1.jpg';
import connor2 from './connor2.jpg';
import connor3 from './connor3.jpg';
import bart1 from './bart1.jpg';
import bart2 from './bart2.jpg';
import bart3 from './bart3.jpg';
import art1 from './art1.jpg';
import art2 from './art2.jpg';
import art3 from './art3.jpg';
import tai1 from './tai1.jpg';
import tai2 from './tai2.jpg';
import tai3 from './tai3.jpg';

export default function thumb(thumbName) {
  if (thumbName === 'damian1') {
    return damian1;
  } else if (thumbName === 'damian2') {
    return damian2;
  } else if (thumbName === 'damian3') {
    return damian3;
  } else if (thumbName === 'jon1') {
    return jon1;
  } else if (thumbName === 'jon2') {
    return jon2;
  } else if (thumbName === 'jon3') {
    return jon3;
  } else if (thumbName === 'connor1') {
    return connor1;
  } else if (thumbName === 'connor2') {
    return connor2;
  } else if (thumbName === 'connor3') {
    return connor3;
  } else if (thumbName === 'bart1') {
    return bart1;
  } else if (thumbName === 'bart2') {
    return bart2;
  } else if (thumbName === 'bart3') {
    return bart3;
  } else if (thumbName === 'art1') {
    return art1;
  } else if (thumbName === 'art2') {
    return art2;
  } else if (thumbName === 'art3') {
    return art3;
  } else if (thumbName === 'tai1') {
    return tai1;
  } else if (thumbName === 'tai2') {
    return tai2;
  } else if (thumbName === 'tai3') {
    return tai3;
  }
}
