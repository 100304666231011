import React from 'react';
import { Link } from 'react-router-dom';
import homeBtn from '../assets/img/home.png';
import homeAnim from '../assets/img/home_anim.gif';
import gallery from '../assets/img/gallery.png';
import galleryAnim from '../assets/img/gallery_anim.gif';
import cast from '../assets/img/cast.png';
import castAnim from '../assets/img/cast_anim.gif';
import StoreLink from '../links/StoreLink';
import { Image, Center, HStack } from '@chakra-ui/react';
import One from '../assets/img/1.png';
import Two from '../assets/img/2.png';
import Three from '../assets/img/3.png';
import OneHl from '../assets/img/1_1.png';
import TwoHl from '../assets/img/2_2.png';
import ThreeHl from '../assets/img/3_3.png';

function Nav() {
  const [animate, setAnimate] = React.useState(false);
  const [animateCast, setAnimateCast] = React.useState(false);
  const [animateGallery, setAnimateGallery] = React.useState(false);
  const [hover, setHover] = React.useState(false);
  const [hoverTwo, setHoverTwo] = React.useState(false);
  const [hoverThree, setHoverThree] = React.useState(false);
  return (
    <Center>
      <div className="bar"></div>
      <Center pos="absolute" zIndex="2" w={['90%', '80%', '50%', '50%']}>
        <HStack>
          <Link to="/">
            <Image
              boxShadow="md"
              onMouseEnter={() => setAnimate(true)}
              onMouseLeave={() => setAnimate(false)}
              src={animate ? homeAnim : homeBtn}
              fallbackSrc={homeBtn}
              alt="home-button"
              className="home-btn"
            />
          </Link>
          <Link to="/gallery">
            <Image
              boxShadow="md"
              onMouseEnter={() => setAnimateGallery(true)}
              onMouseLeave={() => setAnimateGallery(false)}
              src={animateGallery ? galleryAnim : gallery}
              fallbackSrc={gallery}
              alt="gallery"
              className="gallery-btn"
            />
          </Link>
          <Link to="/cast">
            <Image
              boxShadow="md"
              onMouseEnter={() => setAnimateCast(true)}
              onMouseLeave={() => setAnimateCast(false)}
              src={animateCast ? castAnim : cast}
              fallbackSrc={cast}
              alt="cast"
              className="cast-btn"
            />
          </Link>
          <StoreLink />
          <HStack w={['150%', '150%', '300px', '500px']} spacing={0}>
            <a target="_blank" href="https://twitter.com/OtterTheAuthor">
              <Image
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
                src={hover ? OneHl : One}
                fallbackSrc={One}
                alt="One"
              />
            </a>
            <a target="_blank" href="https://www.facebook.com/OtterTheAuthor">
              <Image
                onMouseEnter={() => setHoverTwo(true)}
                onMouseLeave={() => setHoverTwo(false)}
                src={hoverTwo ? TwoHl : Two}
                fallbackSrc={Two}
                alt="Two"
              />
            </a>
            <a
              target="_blank"
              href="https://www.tumblr.com/blog/otter-the-author"
            >
              <Image
                onMouseEnter={() => setHoverThree(true)}
                onMouseLeave={() => setHoverThree(false)}
                src={hoverThree ? ThreeHl : Three}
                fallbackSrc={Three}
                alt="Three"
              />
            </a>
          </HStack>
        </HStack>
      </Center>
    </Center>
  );
}

export default Nav;
