import React from 'react';
import { Box, Text } from '@chakra-ui/react';

function Footer() {
  return (
    <div className="footer-container">
      <Box w="100%" className="footer">
        <Text position="absolute" p="5" w="100%" bottom="0">
          Copyright @OtterTheAuthor
        </Text>
      </Box>
    </div>
  );
}

export default Footer;
