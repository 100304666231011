import React from "react";
import Comics from "./Comics";

function Home() {
  return (
    <div>
      <Comics />
    </div>
  );
}

export default Home;
