import React, { useState, useEffect, useRef } from 'react';
import useFireStore from './firebase/useFireStore';
import { useLocation, Link } from 'react-router-dom';
import {
  Button,
  Box,
  Center,
  Flex,
  Text,
  Heading,
  SkeletonText,
  SkeletonCircle,
  Spinner,
  VStack,
} from '@chakra-ui/react';
import ComicsModal from './containers/ComicsModal';
// import useOnScreen from '../hooks/useOnScreen';
import NewReader from './NewReader';

function Comics() {
  const listInnerRef = useRef();
  const { docs } = useFireStore('comics');
  let location = useLocation();
  let background = location.state && location.state.background;
  const [posts, setAllPosts] = useState();
  const [end, setEnd] = useState(false);
  const [pageNum, setPageNum] = useState(1);
  const [mobilePage, setMobilePage] = useState(1);

  // Ref for the element that we want to detect whether on screen
  // const refShow = useRef();
  // Call the hook passing in ref and root margin
  // In this case it would only be considered onScreen if more ...
  // ... than 300px of element is visible.
  // const onScreen = useOnScreen(refShow, '-300px');

  useEffect(() => {
    if (docs?.length > 5) {
      let comics = [];
      let range = 5 * pageNum;

      if (range > docs.length) {
        range = docs.length;
        setEnd(true);
      }

      if (range < docs.length || mobilePage > 1) {
        let pageNumber = pageNum + 1;
        setPageNum(pageNumber);
      }

      for (let i = 0; i < range; i++) {
        comics.push(docs[i]);
      }

      setAllPosts(comicsList(comics));
    } else if (docs?.length < 5) {
      setAllPosts(comicsList(docs));
      setEnd(true);
    } else if (docs === undefined) {
      setAllPosts(skeletonList());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [docs, mobilePage]);

  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      console.log(scrollTop, scrollHeight, clientHeight);
      if (scrollTop + clientHeight === scrollHeight) {
        // TO SOMETHING HERE
        console.log('Reached bottom');
      }
    }
  };

  const comicsList = (docs) => {
    return docs.map((doc) => (
      <VStack
        boxShadow="full"
        borderRadius="25px"
        bg="white"
        p="5"
        w={['100%', '100%', '800px', '800px']}
        key={doc.id}
      >
        <Flex
          h="100%"
          justifyContent="space-between"
          fontFamily="Sniglet, cursive;"
          w="100%"
        >
          <VStack
            justifyContent="flex-start"
            d="flex"
            justify="flex-start"
            w="100%"
            px="5"
            py="1"
          >
            <Heading w="100%" color="#91C1E2">
              {doc.title}
            </Heading>
            <Text w="100%" color="#91C1E2">
              {doc.desc}
            </Text>
            <Flex flexWrap="wrap" w="100%">
              {doc.tags.map((tag, id) => (
                <Text
                  mx="1"
                  my="1"
                  px="3"
                  key={id}
                  borderRadius="3xl"
                  color="white"
                  bg="#92C3E0"
                >
                  {tag}
                </Text>
              ))}
            </Flex>
          </VStack>
        </Flex>
        <ComicsModal objectFit={'contain'} background={background} doc={doc} />
      </VStack>
    ));
  };

  function skeletonList() {
    let skeletonArray = [1, 2, 3, 4, 5];

    return skeletonArray.map((doc, index) => (
      <VStack
        boxShadow="full"
        borderRadius="25px"
        bg="white"
        p="5"
        w={['100%', '100%', '800px', '800px']}
        key={index}
      >
        <Box p="5" h="800px" w={['100%', '100%', '800px', '800px']}>
          <SkeletonCircle size="10" />
          <SkeletonText mt="4" noOfLines={4} spacing="4" />
          <Center h="100%">
            <Spinner />
          </Center>
        </Box>
      </VStack>
    ));
  }

  function nextPage() {
    let mobileNum = mobilePage + 4;
    let num = pageNum + 1;
    setPageNum(num);
    setMobilePage(mobileNum);
  }

  return (
    <Center
      bg="linear-gradient(
      0deg,
      rgba(204, 230, 245, 1) 0%,
      rgba(180, 215, 237, 1) 37%,
      rgba(145, 194, 224, 1) 100%
    );"
      onScroll={() => onScroll()}
    >
      <Link to="/newreader">
        <NewReader />
      </Link>

      <VStack w="100%" mb="20" mt="20" spacing="20">
        {posts}
        <Button
          bg="#16A985"
          _hover={{ background: '#16A985' }}
          borderWidth="2.5px"
          borderColor="white"
          onClick={nextPage}
          color="white"
        >
          {docs === undefined ? 'Loading...' : 'Load More'}
        </Button>
      </VStack>
    </Center>
  );
}

export default Comics;
