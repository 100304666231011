import React from 'react';
import Nav from './Nav';
import LOGO from '../assets/logo.png';
import { Image, Center } from '@chakra-ui/react';
import Cloud1 from '../assets/Cloud_1.png';
import Cloud2 from '../assets/Cloud_2.png';
import Cloud3 from '../assets/Cloud_3.png';
import Cloud4 from '../assets/Cloud_4.png';

function Banner() {
  return (
    <Center overflow="hidden" className="banner" overflowX="hidden">
      <Center
        backgroundSize="contain"
        overflow="hidden"
        w="100%"
        h="100%"
        overflowX="hidden"
        position="relative"
      >
        <Image
          className="cloud1"
          pos="absolute"
          zIndex="4"
          alt="cloud1"
          draggable={false}
          src={Cloud1}
        />

        <Image
          className="cloud2"
          pos="absolute"
          zIndex="4"
          alt="cloud1"
          draggable={false}
          src={Cloud2}
        />

        <Image
          className="cloud3"
          pos="absolute"
          zIndex="4"
          alt="cloud1"
          draggable={false}
          src={Cloud3}
        />

        <Image
          className="cloud4"
          pos="absolute"
          zIndex="4"
          alt="cloud1"
          draggable={false}
          src={Cloud4}
        />
        <Image
          pos="absolute"
          zIndex="4"
          w={['80%', '80%', '40%', '40%']}
          alt="logo"
          draggable={false}
          src={LOGO}
        />
      </Center>
      <Nav />
    </Center>
  );
}

export default Banner;
