import React, { useState, useRef } from 'react';
// import {Route,Link, useParams} from 'react-router-dom'
// import useFireStore from '../firebase/useFireStore'
import {
  useDisclosure,
  Button,
  Image,
  VStack,
  Flex,
  Heading,
  Modal,
  Center,
  Text,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from '@chakra-ui/react';
import { CopyIcon, CheckIcon } from '@chakra-ui/icons';
import copy from 'copy-to-clipboard';

export default function ComicsModal({ doc, objectFit, height }) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  function useCopyToClipboard(resetInterval = null) {
    const [isCopied, setCopied] = useState(false);

    function handleCopy(text) {
      if (typeof text === 'string' || typeof text == 'number') {
        copy(text.toString());
        setCopied(true);
      } else {
        setCopied(false);
        console.error(
          `Cannot copy typeof ${typeof text} to clipboard, must be a string or number.`
        );
      }
    }
    return [isCopied, handleCopy];
  }

  function CopyButton({ code }) {
    const [isCopied, handleCopy] = useCopyToClipboard();

    return (
      <Button mx="2" h="2rem" onClick={() => handleCopy(code)}>
        {isCopied ? <CheckIcon /> : <CopyIcon />}
      </Button>
    );
  }

  const btnRef = useRef();
  return (
    <>
      <Image
        borderRadius="15px"
        cursor="pointer"
        w={['100%', '100%', '800px', '800px']}
        h={height}
        objectFit={`${objectFit}`}
        src={doc.url}
        onClick={onOpen}
      />

      <Modal
        blockScrollOnMount={false}
        size="4xl"
        onClose={onClose}
        finalFocusRef={btnRef}
        isOpen={isOpen}
        scrollBehavior={'outside'}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader></ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Center flexDir="column">
              <Flex
                h="100%"
                justifyContent="space-between"
                fontFamily="Sniglet, cursive;"
                w="100%"
              >
                <VStack
                  justifyContent="flex-start"
                  d="flex"
                  justify="flex-start"
                  w="100%"
                  px="5"
                  py="1"
                >
                  <Heading
                    alignItems="center"
                    justifyContent="center"
                    w="100%"
                    color="#91C1E2"
                  >
                    {doc.title}
                    <CopyButton
                      code={`https://littlelads.art/comic/${doc.id}`}
                    />
                  </Heading>
                  <Text w="100%" color="#91C1E2">
                    {doc.desc}
                  </Text>
                  <Flex flexWrap="wrap" w="100%">
                    {doc.tags.map((tag, id) => (
                      <Text
                        mx="1"
                        my="1"
                        px="3"
                        key={id}
                        borderRadius="3xl"
                        color="white"
                        bg="#92C3E0"
                      >
                        {tag}
                      </Text>
                    ))}
                  </Flex>
                </VStack>
              </Flex>
              <Image h="100%" objectFit="cover" src={doc.url} alt="uploaded" />
            </Center>
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
