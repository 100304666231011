import React from 'react';
import Damian from '../assets/char/Damian.gif';
import Bart from '../assets/char/Bart.gif';
import Connor from '../assets/char/Connor.gif';
import Art from '../assets/char/Art.gif';
import Jon from '../assets/char/Jon.gif';
import Tai from '../assets/char/Tai.gif';
import Saeby from '../assets/char/Saeby.png';
import Otto from '../assets/char/Otto.png';
import Cubbie from '../assets/char/Cubbie.png';
import thumb from '../assets/char/thumbnail/thumb';
import {
  Center,
  Box,
  Text,
  Image,
  HStack,
  Stack,
  VStack,
  Heading,
} from '@chakra-ui/react';
import './Cast.css';

function Cast() {
  return (
    <Center
      fontFamily="Roboto Condensed"
      py="20"
      h="100%"
      bg="linear-gradient(
    0deg,
    rgba(204, 230, 245, 1) 0%,
    rgba(180, 215, 237, 1) 37%,
    rgba(145, 194, 224, 1) 100%
  );"
    >
      <VStack
        boxShadow="full"
        borderRadius="25px"
        bg="white"
        p="5"
        w={['100%', '90%', '50%', '50%']}
        h="100%"
      >
        <HStack h={['100%', '100%', '100%', '100%']} p="5">
          <Image
            px="3"
            w={['150px', '150px', '200px', '300px']}
            objectFit="cover"
            src={Damian}
            alt="uploaded"
          />
          <VStack h="100%" w="100%" fontFamily="Roboto Condensed">
            <Text w="100%" textAlign="left">
              ROBIN
            </Text>
            <Heading w="100%" textAlign="left" fontFamily="Roboto Condensed">
              DAMIAN WAYNE
            </Heading>
            <Text w="100%" textAlign="left">
              Son of Bruce Wayne (Batman) and Talia al-Ghul. Dynamic Duo of
              Super Sons, Former team leader of Teen Titans. Loves animals more
              human (probably).
            </Text>
            <Text w="100%" textAlign="left">
              Origin: Batman and Robin (2011), Super Sons (2017)
            </Text>
            <Text w="100%" textAlign="left">
              Featured:
            </Text>
            <Stack direction="row" w="100%" h="100%">
              <Box borderStyle="solid" borderWidth="1px" w="100%" h="100%">
                <Image
                  cursor="pointer"
                  onClick={() =>
                    window.open(
                      'https://littlelads.art/story/su478DxOSKBYdaVntepu',
                      '_blank'
                    )
                  }
                  w="100%"
                  h="100%"
                  src={thumb('damian1')}
                />
              </Box>
              <Box borderStyle="solid" borderWidth="1px" w="100%" h="100%">
                <Image
                  cursor="pointer"
                  onClick={() =>
                    window.open(
                      'https://littlelads.art/comic/TYTcMTmYGrpSyFV0ekPR',
                      '_blank'
                    )
                  }
                  w="100%"
                  h="100%"
                  src={thumb('damian2')}
                />
              </Box>
              <Box borderStyle="solid" borderWidth="1px" w="100%" h="100%">
                <Image
                  cursor="pointer"
                  onClick={() =>
                    window.open(
                      'https://littlelads.art/comic/lNBJEeMpcCmxD8VYVRHw',
                      '_blank'
                    )
                  }
                  w="100%"
                  h="100%"
                  src={thumb('damian3')}
                />
              </Box>
            </Stack>
          </VStack>
        </HStack>

        <HStack h={['100%', '100%', '100%', '100%']} p="5">
          <VStack h="100%" w="100%" fontFamily="Roboto Condensed">
            <Text w="100%" textAlign="right">
              SUPERBOY
            </Text>
            <Heading w="100%" textAlign="right" fontFamily="Roboto Condensed">
              JON KENT
            </Heading>
            <Text w="100%" textAlign="left">
              Son of Clark Kent (Superman) and Lois Lane. Hybrid of Human and
              Kryptonian. Merged into DC Mainline Continuity in the "DC:
              Convergence" event. A huge anime nerd and a bright sunshine
              wherever he goes.
            </Text>
            <Text w="100%" textAlign="left">
              Origin: Superman #7 (2016), Super Sons (2017)
            </Text>
            <Text w="100%" textAlign="left">
              Featured:
            </Text>
            <Stack direction="row" w="100%" h="100%">
              <Box borderStyle="solid" borderWidth="1px" w="100%" h="100%">
                <Image
                  cursor="pointer"
                  onClick={() =>
                    window.open(
                      'https://littlelads.art/story/s56QH2toXawxwrJQTKqh',
                      '_blank'
                    )
                  }
                  w="100%"
                  h="100%"
                  src={thumb('jon1')}
                />
              </Box>
              <Box borderStyle="solid" borderWidth="1px" w="100%" h="100%">
                <Image
                  cursor="pointer"
                  onClick={() =>
                    window.open(
                      'https://littlelads.art/comic/b4SrbnBcNw02395OC3aI',
                      '_blank'
                    )
                  }
                  w="100%"
                  h="100%"
                  src={thumb('jon2')}
                />
              </Box>
              <Box borderStyle="solid" borderWidth="1px" w="100%" h="100%">
                <Image
                  cursor="pointer"
                  onClick={() =>
                    window.open(
                      'https://littlelads.art/comic/AM5FxSLoi87t5tW4J4zf',
                      '_blank'
                    )
                  }
                  w="100%"
                  h="100%"
                  src={thumb('jon3')}
                />
              </Box>
            </Stack>
          </VStack>
          <Image
            px="3"
            w={['150px', '150px', '200px', '300px']}
            objectFit="cover"
            src={Jon}
            alt="uploaded"
          />
        </HStack>

        <HStack h={['100%', '100%', '100%', '100%']} p="5">
          <Image
            px="3"
            w={['150px', '150px', '200px', '300px']}
            objectFit="cover"
            src={Art}
            alt="uploaded"
          />
          <VStack h="100%" w="100%" fontFamily="Roboto Condensed">
            <Text w="100%" textAlign="left">
              AQUABOY
            </Text>
            <Heading w="100%" textAlign="left" fontFamily="Roboto Condensed">
              ARTHUR "ART" CURRY JR.
            </Heading>
            <Text w="100%" textAlign="left">
              Son of Arthur Curry (Aquaman) and Mera. Lives his whole life under
              the sea until being recruited into the Little Lads. Oblivious to
              "Land Culture". Talks to fish (and other aquatic animals.). Scared
              of Thunder.
            </Text>
            <Text w="100%" textAlign="left">
              Origin: Reimagination of baby Arthur Curry Jr. from Justice League
              Animated EP7, Aquaman #23 (1965), Young Justice S03E07
            </Text>
            <Text w="100%" textAlign="left">
              Featured:
            </Text>
            <Stack direction="row" w="100%" h="100%">
              <Box borderStyle="solid" borderWidth="1px" w="100%" h="100%">
                <Image
                  cursor="pointer"
                  onClick={() =>
                    window.open(
                      'https://littlelads.art/story/eMXz21OQ8IsMPlPZ5MC3',
                      '_blank'
                    )
                  }
                  w="100%"
                  h="100%"
                  src={thumb('art1')}
                />
              </Box>
              <Box borderStyle="solid" borderWidth="1px" w="100%" h="100%">
                <Image
                  cursor="pointer"
                  onClick={() =>
                    window.open(
                      'https://littlelads.art/story/YSW0iek15cEzT4Aw76q4',
                      '_blank'
                    )
                  }
                  w="100%"
                  h="100%"
                  src={thumb('art2')}
                />
              </Box>
              <Box borderStyle="solid" borderWidth="1px" w="100%" h="100%">
                <Image
                  cursor="pointer"
                  onClick={() =>
                    window.open(
                      'https://littlelads.art/story/d1cKFs5Xjr4ueAH1AfX5',
                      '_blank'
                    )
                  }
                  w="100%"
                  h="100%"
                  src={thumb('art3')}
                />
              </Box>
            </Stack>
          </VStack>
        </HStack>

        <HStack h={['100%', '100%', '100%', '100%']} p="5">
          <VStack h="100%" w="100%" fontFamily="Roboto Condensed">
            <Text w="100%" textAlign="right">
              GREEN LANTERN
            </Text>
            <Heading w="100%" textAlign="right" fontFamily="Roboto Condensed">
              TAI PHAM
            </Heading>
            <Text w="100%" textAlign="left">
              Grandson of Kim Tran (Former GL). Inherits the power ring from
              her. A Green Lantern trainee. Book smart and artistic.
            </Text>
            <Text w="100%" textAlign="left">
              Origin: Green Lantern: Legacy
            </Text>
            <Text w="100%" textAlign="left">
              Featured:
            </Text>
            <Stack direction="row" w="100%" h="100%">
              <Box borderStyle="solid" borderWidth="1px" w="100%" h="100%">
                <Image
                  cursor="pointer"
                  onClick={() =>
                    window.open(
                      'https://littlelads.art/story/OSmB1EYHGVhsbTcYwoe6',
                      '_blank'
                    )
                  }
                  w="100%"
                  h="100%"
                  src={thumb('tai1')}
                />
              </Box>
              <Box borderStyle="solid" borderWidth="1px" w="100%" h="100%">
                <Image
                  cursor="pointer"
                  onClick={() =>
                    window.open(
                      'https://littlelads.art/story/d1cKFs5Xjr4ueAH1AfX5',
                      '_blank'
                    )
                  }
                  w="100%"
                  h="100%"
                  src={thumb('tai2')}
                />
              </Box>
              <Box borderStyle="solid" borderWidth="1px" w="100%" h="100%">
                <Image
                  cursor="pointer"
                  onClick={() =>
                    window.open(
                      'https://littlelads.art/story/64Tdwn23K3xHi8ZlinJK',
                      '_blank'
                    )
                  }
                  w="100%"
                  h="100%"
                  src={thumb('tai3')}
                />
              </Box>
            </Stack>
          </VStack>
          <Image
            px="3"
            w={['150px', '150px', '200px', '300px']}
            objectFit="cover"
            src={Tai}
            alt="uploaded"
          />
        </HStack>

        <HStack h={['100%', '100%', '100%', '100%']} p="5">
          <Image
            px="3"
            w={['150px', '150px', '200px', '300px']}
            objectFit="cover"
            src={Connor}
            alt="uploaded"
          />
          <VStack h="100%" w="100%" fontFamily="Roboto Condensed">
            <Text w="100%" textAlign="left">
              QUICKDRAW
            </Text>
            <Heading w="100%" textAlign="left" fontFamily="Roboto Condensed">
              CONNOR LANCE-QUEEN
            </Heading>
            <Text w="100%" textAlign="left">
              Son of Oliver (Green Arrow) and Dinah Lance (Black Canary). Able
              to project supersonic scream like his mother called "Connor-ry
              Cry". Scared of the dark. Loves his wolf pup Saeby.
            </Text>
            <Text w="100%" textAlign="left">
              Origin: Injustice: Gods Among Us: Year Two #9
            </Text>
            <Text w="100%" textAlign="left">
              Featured:
            </Text>
            <Stack direction="row" w="100%" h="100%">
              <Box borderStyle="solid" borderWidth="1px" w="100%" h="100%">
                <Image
                  cursor="pointer"
                  onClick={() =>
                    window.open(
                      'https://littlelads.art/story/MMyJmCo218ha96A79X9O',
                      '_blank'
                    )
                  }
                  w="100%"
                  h="100%"
                  src={thumb('connor1')}
                />
              </Box>
              <Box borderStyle="solid" borderWidth="1px" w="100%" h="100%">
                <Image
                  cursor="pointer"
                  onClick={() =>
                    window.open(
                      'https://littlelads.art/story/pHr7LWgk46jpkVfx4gde',
                      '_blank'
                    )
                  }
                  w="100%"
                  h="100%"
                  src={thumb('connor2')}
                />
              </Box>
              <Box borderStyle="solid" borderWidth="1px" w="100%" h="100%">
                <Image
                  cursor="pointer"
                  onClick={() =>
                    window.open(
                      'https://littlelads.art/story/xs20KfptNsMN0gFyQwEF',
                      '_blank'
                    )
                  }
                  w="100%"
                  h="100%"
                  src={thumb('connor3')}
                />
              </Box>
            </Stack>
          </VStack>
        </HStack>

        <HStack h={['100%', '100%', '100%', '100%']} p="5">
          <VStack h="100%" w="100%" fontFamily="Roboto Condensed">
            <Text w="100%" textAlign="right">
              IMPULSE
            </Text>
            <Heading w="100%" textAlign="right" fontFamily="Roboto Condensed">
              BART ALLEN
            </Heading>
            <Text w="100%" textAlign="left">
              Grandson of Barry Allen (The Flash). Time travelled from the
              future and is now stuck in present time. Always gets disturbed by
              the Lads' pets.
            </Text>
            <Text w="100%" textAlign="left">
              Origin: Young Justice S02E06
            </Text>
            <Text w="100%" textAlign="left">
              Featured:
            </Text>
            <Stack direction="row" w="100%" h="100%">
              <Box borderStyle="solid" borderWidth="1px" w="100%" h="100%">
                <Image
                  cursor="pointer"
                  onClick={() =>
                    window.open(
                      'https://littlelads.art/comic/zaTAkosB6SUuH4ABrVIo',
                      '_blank'
                    )
                  }
                  w="100%"
                  h="100%"
                  src={thumb('bart1')}
                />
              </Box>
              <Box borderStyle="solid" borderWidth="1px" w="100%" h="100%">
                <Image
                  cursor="pointer"
                  onClick={() =>
                    window.open(
                      'https://littlelads.art/comic/uK9kZzZZZjUpot3AhbRN',
                      '_blank'
                    )
                  }
                  w="100%"
                  h="100%"
                  src={thumb('bart2')}
                />
              </Box>
              <Box borderStyle="solid" borderWidth="1px" w="100%" h="100%">
                <Image
                  cursor="pointer"
                  onClick={() =>
                    window.open(
                      'https://littlelads.art/comic/jLu1aV0LzwmVlqFb9JNN',
                      '_blank'
                    )
                  }
                  w="100%"
                  h="100%"
                  src={thumb('bart3')}
                />
              </Box>
            </Stack>
          </VStack>
          <Image
            px="3"
            w={['150px', '150px', '200px', '300px']}
            objectFit="cover"
            src={Bart}
            alt="uploaded"
          />
        </HStack>
        <Heading fontFamily="Roboto Condensed">The Lads' Pets</Heading>
        <HStack h={['100%', '100%', '100%', '100%']}>
          <VStack fontFamily="Roboto Condensed">
            <Text>OTTO</Text>
            <Image
              w={['150px', '150px', '200px', '200px']}
              objectFit="cover"
              src={Otto}
              alt="uploaded"
            />
            <HStack></HStack>
          </VStack>

          <VStack fontFamily="Roboto Condensed">
            <Text>SAEBY</Text>
            <Image
              w={['150px', '150px', '200px', '200px']}
              objectFit="cover"
              src={Saeby}
              alt="uploaded"
            />
            <HStack></HStack>
          </VStack>

          <VStack fontFamily="Roboto Condensed">
            <Text>CUBBIE</Text>
            <Image
              w={['150px', '150px', '200px', '200px']}
              objectFit="cover"
              src={Cubbie}
              alt="uploaded"
            />
            <HStack></HStack>
          </VStack>
        </HStack>
      </VStack>
    </Center>
  );
}

export default Cast;
