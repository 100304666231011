import React from 'react';
import RoadSign from '../assets/img/Road_Sign_Site.png';
import { Box, Center, Text } from '@chakra-ui/react';
function NewReader() {
  return (
    <div>
      <div className="new-reader">
        <div>
          <img src={RoadSign} alt="New Reader" />
        </div>
      </div>
      <div className="new-reader-mobile">
        <div>New Reader? Click here!</div>
      </div>
    </div>
  );
}

export default NewReader;
