import * as firebase from 'firebase/app';
import 'firebase/storage';
import 'firebase/firestore';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCo4bUeysj5x8ior6AW18im0Gei_3RSL0g",
  authDomain: "llads-v2.firebaseapp.com",
  databaseURL: "https://llads-v2.firebaseio.com",
  projectId: "llads-v2",
  storageBucket: "llads-v2.appspot.com",
  messagingSenderId: "245270510931",
  appId: "1:245270510931:web:354b6d555b96e5b0c935a6"
};
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);


  const projectStorage = firebase.storage();
  const projectFirestore = firebase.firestore();
  const timestamp = firebase.firestore.FieldValue.serverTimestamp;

  export { projectFirestore, projectStorage, timestamp, firebase as default };