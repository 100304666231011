import React from 'react';
import storeLink from '../assets/img/store.png';
import storeAnimate from '../assets/img/store_anim.gif';
// import storeDisabled from '../assets/img/Store_grey.png';
import { Image } from '@chakra-ui/react';

const StoreLink = () => {
  const [animateStore, setAnimateStore] = React.useState(false);


  return (
    <a href="https://otty.bigcartel.com" target="_blank" >
      <Image
        boxShadow="md"
        
        alt="icon"
        className='store-btn'
        onMouseEnter={() => setAnimateStore(true)}
        onMouseLeave={() => setAnimateStore(false)}
        fallbackSrc={storeLink}
        src={animateStore ? storeAnimate : storeLink}
      />
    </a>
  );
}

export default StoreLink;
