import React from "react";
import Home from "./components/Home";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Banner from "./components/Banner";
import Footer from "./components/Footer";
import Gallery from "./components/Gallery";
import ComicsNewReader from "./components/ComicsNewReader";
import Cast from "./components/Cast";
import {Box} from '@chakra-ui/react'
import ComicsModal from "./components/containers/ComicsModal";
import LinkedComic from "./components/containers/LinkedComic";
import LinkedComicChrono from "./components/containers/LinkedComicChrono";

function App() {
  return (
    <BrowserRouter>
      <Banner />
      <Box >
        <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/gallery" component={Gallery} />
        <Route exact path="/newreader" component={ComicsNewReader} />
        <Route exact path="/cast" component={Cast} />
        <Route path="/comic/:id" children={<LinkedComic/>} />

        <Route path="/story/:id" children={<LinkedComicChrono/>} />

        </Switch>
        <Footer />
      </Box>
    </BrowserRouter>
  );
}

export default App;
